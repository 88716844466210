import { CabButton } from "@CabComponents/CabButton";
import { CabModal } from "@CabComponents/CabModal";
import { Meeting } from "../../store";

export type DuplicateMeetingModalProps = {
  open: boolean
  onClose: () => void
  meeting?: Meeting
  submitDelete: (meeting: Meeting) => void
};

const DeleteMeetingModal = (
  { open, onClose, meeting, submitDelete }: DuplicateMeetingModalProps
): React.ReactElement => {

  return <>
    {meeting ? <CabModal
      open={open}
      onClose={onClose}
      isAlert={true}
      noFullScreen={true}
      text={meeting.is_poll 
        ? 'Are you sure you want to delete this poll?' 
        : 'Are you sure you want to delete this meeting record?'}
      actionButtons={
        <>
          <CabButton buttonType='secondary' onClick={onClose}>
            Cancel
          </CabButton>
          <CabButton onClick={() => submitDelete(meeting)} color="error">
            Delete
          </CabButton>
        </>
      }
    />
      :
      <></>
    }
  </>;
};

export default DeleteMeetingModal;