import { ReactElement, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import {
  BookingSlot,
  Calendar, DisplayCalendarsDict, LeaderList, Meeting, MeetingRoom, MeetingSlot, ParticipantAutocompleteOption, 
  PresetLocations, PrivateExternalParticipant, User, ZoomSettings
} from "../../../store";
import { Meeting as BookMeeting } from "../../../pages/BookMeeting";
import { useMountEffect } from "../../../utils/hooks";
import ClipboardJS from "clipboard";
import { Clipboard } from '@capacitor/clipboard';
import { getBookingLinkUrl, getLocalTimeZoneName, getTemplateVars } from "../../../utils/scheduleUtils";
import { Box, Divider, Snackbar, SnackbarCloseReason, Typography, useMediaQuery } from "@mui/material";
import { trackEvent } from "../../../utils/appAnalyticsUtils";
import { EVENT_TYPE, PROVIDER, PROVIDER_CONFERENCE_TYPES } from "../../../constants";
import { CabModal } from "@CabComponents/CabModal";
import { CabTextTokenDisplay } from "@CabComponents/CabTextTokenDisplay";
import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import colors from "../../../colors";
import CopyLink from "../CopyLink/CopyLink";
import BookingOptionsPanel from "./BookingOptionsPanel";
import ShareTimes from "./ShareTimes";
import AttendeeList, { AttendeeListProps } from "./AttendeeList";
import { uniqBy } from "lodash-es";
import { LocationsFormInput } from "../MeetingSettings/MeetingSettings";
import { transformSimpleTemplateToText } from "@CabComponents/CabTextTokenInput";
import { getConferenceProvidersForLeadersParameterized } from "../../../store/schedule/selectors";
import { CabTooltip } from "@CabComponents/CabTooltip";
import UnavailableTimesModal from "./UnavailableTimesModal";
import { IoOpenOutline, IoCalendarClearOutline, IoLocationOutline, IoCreateOutline } from "react-icons/io5";
import theme from "../../../theme";

export interface ScheduleShareModalProps {
  onModalClose: () => void;
  modalOpen: boolean;
  onChangeValues: (meetingPartial: Partial<Meeting>) => Promise<void>;
  isOwner: boolean;
  calendars: Calendar[];
  meetingRooms: {[id: string]: MeetingRoom};
  presetLocations: PresetLocations;
  attendees: PrivateExternalParticipant[];
  onAllowVotersToSelectFromList: (allow: boolean) => Promise<void>;
  onDeleteAttendee: (attendeeId: number) => Promise<void>;
  onAddAttendee: AttendeeListProps['onAddAttendee'];
  onUpdateAttendee: AttendeeListProps['onUpdateAttendee']
  participantAutocompleteOptions: ParticipantAutocompleteOption[];
  fetchAutocompleteOptions: (value: string) => void;
  onEdit: () => void;
  showEdit?: boolean;
  setEndDate?: (date: string) => void;
  endDate?: string;
  onAdditionalTimezoneChange: (timezone: string[] | null) => void;
  defaultAdditionalTimezones: (string | null)[];
  formattedSlots: string;
  hyperlinkedSlots: string;
  unavailableSlots: BookingSlot[];
  templateTimezone: string | null;
  onTemplateTimezoneSelect: (timezone: string) => void;
  additionalCopyTimezones: string[];
  loadingAdditionalTimezone: boolean;
  meeting: Meeting;
  zoomSettings: {
    [settingsId: string]: ZoomSettings;
  };
  user: User | null | undefined;
  leaders: LeaderList;
  displayedCalendars: DisplayCalendarsDict;
  mergeSlots: boolean;
  handleToggleMergeSlots: () => void;
  loadingTimes: boolean;
  openUpgradeModal: () => void;
  currentMeetingSlots: MeetingSlot[];
  meetingSlotsOutOfRange: boolean;
  futureMeetingSlots: MeetingSlot[];
  timezone: string | null;
}

const ScheduleShareModal = (
  {
    formattedSlots, templateTimezone, onTemplateTimezoneSelect, onChangeValues, onModalClose, modalOpen, meeting, user, 
    attendees, onAllowVotersToSelectFromList, onDeleteAttendee, onAddAttendee, onUpdateAttendee, leaders, meetingRooms,
    participantAutocompleteOptions, fetchAutocompleteOptions, onEdit, showEdit, 
    hyperlinkedSlots, endDate, setEndDate, additionalCopyTimezones, onAdditionalTimezoneChange, zoomSettings,
    loadingAdditionalTimezone, defaultAdditionalTimezones, mergeSlots, handleToggleMergeSlots, loadingTimes, 
    currentMeetingSlots, openUpgradeModal, unavailableSlots, displayedCalendars, presetLocations, 
    calendars, isOwner, meetingSlotsOutOfRange, futureMeetingSlots, timezone
  }: ScheduleShareModalProps
): ReactElement => {
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [copyAlert, setCopyAlert] = useState(false);
  const [isCopyError, setIsCopyError] = useState(false);
  const [emptyMessage, setEmptyMessage] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [showTimesWithLinks, setShowTimesWithLinks] = useState(true);
  const [unavailableTimesModalOpen, setUnavailableTimesModalOpen] = useState<boolean>(false);

  const hasAttendeeListOptions = (meeting.is_poll && user?.features.PREREGISTERED_ATTENDEES) || false;
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));


  useMountEffect(() => {
    const clipboard = new ClipboardJS('#copyTimesWithLinksButton');
    clipboard.on('success', function (e: { clearSelection: () => void; }) {
      setCopyAlert(true);
      e.clearSelection();
      setTimeout((): void => {
        setCopyAlert(false);
      }, 750);
    });

    clipboard.on('error', function () {
      setIsCopyError(true);
    });
    return () => clipboard.destroy();
  });

  const copyToClipboard = (textToCopy: string): void => {
    Clipboard.write({
      string: textToCopy
    }).then(() => {
      setCopyAlert(true);
      setTimeout((): void => {
        setCopyAlert(false);
      }, 750);
    });
  };

  useEffect(() => {
    if (formattedSlots.length === 0) {
      if (currentMeetingSlots.length === 0) {
        setEmptyMessage('You have not selected any available times for this meeting. To continue, please select'
          + ' at least one time on the calendar.');
      } else if (futureMeetingSlots.length === 0) {
        setEmptyMessage('None of your selected slots are far enough in the future to be offered.');
      } else if (futureMeetingSlots.length > 0 && meetingSlotsOutOfRange) {
        setEmptyMessage('Choose a later date for "Show availability until" under Text Settings to see more times.');
      } else {
        setEmptyMessage(`There are times selected that conflict with events on the calendar. \n\n`
        + 'You can disable "Prevent conflicts" to show these times.');
      }
    } else {
      setEmptyMessage('');
    }

    if (unavailableSlots.length > 0) {
      setInfoMessage(isSmDown ? 'Some times are unavailable' : 'Some selected times are unavailable for booking.');
    } else {
      setInfoMessage('');
    }
  }, [currentMeetingSlots.length, formattedSlots.length, futureMeetingSlots.length, unavailableSlots.length, 
    meetingSlotsOutOfRange, isSmDown]);

  const handleChangeAdditionalCopyTimezone = (value: string | null, idx: number) => {
    if (value != null) {
      const copyAdditionalTz = [...additionalCopyTimezones];
      copyAdditionalTz[idx] = value;
      onAdditionalTimezoneChange(copyAdditionalTz);
    }
  };

  const handleRemoveAdditionalCopyTimezone = (idx: number) => {
    const copyAdditionalTz = [...additionalCopyTimezones];
    delete copyAdditionalTz[idx];
    onAdditionalTimezoneChange(copyAdditionalTz.filter(v => v));
  };

  const handleAddAdditionalCopyTimezone = () => {
    const additionalTimezoneIdx = additionalCopyTimezones.length;

    const newTimezoneFromDefaults = defaultAdditionalTimezones.length > additionalTimezoneIdx
      ? defaultAdditionalTimezones[additionalTimezoneIdx] || getLocalTimeZoneName()
      : getLocalTimeZoneName();
    onAdditionalTimezoneChange([...additionalCopyTimezones, newTimezoneFromDefaults]);
  };

  const copyTimes = useCallback(() => {
    copyToClipboard(formattedSlots);
    trackEvent(EVENT_TYPE.SCHEDULING_COPY_SLOTS);
  }, [formattedSlots]);

  const handleModalClose = () => {
    if (!openPreview) {
      onModalClose();
    } else {
      setOpenPreview(false);
    }
  };

  const handleToggleShowTimesWithLinks = () => {
    setShowTimesWithLinks(!showTimesWithLinks);
  };

  const templateVars = useMemo(() => {
    return getTemplateVars(meeting.questions);
  }, [meeting.questions]);

  const templateNameLookup = Object.entries(templateVars)
    .map(([k, v]) => ({ [k]: v.replaceText }))
    .reduce((a, b) => ({ ...a, ...b }), {});

  const meetingLocations: LocationsFormInput = useMemo(() => {
    const locations: LocationsFormInput['locations'] = [
      ...(meeting.conference_provider && meeting.conference_provider !== PROVIDER.NO_PROVIDER.id
        ? [{ conference: { provider: meeting.conference_provider, leader: meeting.conference_leader || null } }]
        : []
      ),
      ...(meeting.rooms || []).map(roomId => ({ room: roomId })),
      ...(meeting.location_presets || []).map(presetLoc => ({ presetLocation: presetLoc })),
      ...(meeting.locations || []).map(loc => ({
        locationText: transformSimpleTemplateToText(loc, templateNameLookup),
      })),
    ];

    if (locations.length === 0) {
      locations.push({ none: true });
    }

    return {
      locations,
    };
  }, [meeting.conference_leader, meeting.conference_provider, meeting.locations,
    meeting.rooms, meeting.location_presets, templateNameLookup]);

  const calendar = useMemo(
    () => calendars.find(
      (cal) => meeting.booking_calendar === cal.id), [meeting.booking_calendar, calendars]
  );

  const [conferenceOptions, ] = useMemo(() => {
    let availableConferenceProviders: { conferenceProviderId: number; leaderId: number | null; label: string }[] = [];
    if (calendar && PROVIDER_CONFERENCE_TYPES[calendar.provider]) {
      const allProviderSolutions = PROVIDER_CONFERENCE_TYPES[calendar.provider];
      const allowedProviderSolutions = calendar.conferenceSolutions;
      allowedProviderSolutions.forEach(allowedSolutionKey => {
        const foundSolution = allProviderSolutions.find(
          providerSolution => providerSolution.key === allowedSolutionKey
        );
        if (foundSolution) {
          availableConferenceProviders.push({
            conferenceProviderId: foundSolution.id,
            leaderId: null,
            label: foundSolution.label
          });
        }
      });
  
    }
  
    // add anything else that is not Google or MS
    const others = getConferenceProvidersForLeadersParameterized(
      user, zoomSettings, leaders, meeting?.leaders || []
    ).filter(
      provider => ![PROVIDER.GOOGLE.id, PROVIDER.MICROSOFT.id].includes(provider.conferenceProviderId)
    );
  
    availableConferenceProviders = [...availableConferenceProviders, ...others];
    const hasZoomOptions = availableConferenceProviders.some(p => p.conferenceProviderId === PROVIDER.ZOOM.id);
    const availableOptions = availableConferenceProviders.map((provider) => {
      return {
        value: {
          provider: provider.conferenceProviderId,
          leader: provider.leaderId,
        },
        label: provider.label,
      };
    });
  
    // add meeting's current conference as an option ONLY if you don't own it. Otherwise duplicates may appear.
    if (!isOwner && meeting?.conference_provider) {
      let providerInfo: { id: number; label: string } | undefined;
      Object.values(PROVIDER_CONFERENCE_TYPES).forEach(providerOptions => (
        providerOptions.forEach(conferenceProvider => {
          if (conferenceProvider.id === meeting.conference_provider) {
            providerInfo = conferenceProvider;
          }
        })
      ));
      if (providerInfo) {
        const confLeaderId = meeting.conference_leader || null;
        let labelAdditional = '';
        if (confLeaderId && providerInfo.id === PROVIDER.ZOOM.id) {
          const confLeader = meeting.leader_info?.find(l => l.id === confLeaderId);
          if (confLeader) {
            let leaderFullName = confLeader ? confLeader.first_name : '';
            if (confLeader?.last_name) leaderFullName += ` ${confLeader.last_name[0]}`;
            labelAdditional = ` - ${leaderFullName}`;
          }
          const confLeaderZoomSettings = Object.values(zoomSettings).find(stg => stg.leader === confLeaderId);
          const zoomLabel = confLeaderZoomSettings?.schedule_obo?.username
            ? ` (${confLeaderZoomSettings.schedule_obo.username})`
            : confLeaderZoomSettings?.oauth_token?.username
              ? ` (${confLeaderZoomSettings?.oauth_token?.username})`
              : '';
          labelAdditional += zoomLabel;
        }
  
        availableOptions.push({
          value: {
            provider: providerInfo.id,
            leader: meeting.conference_leader || null,
          },
          label: `${providerInfo.label}${labelAdditional}`,
        });
      }
    }
  
    return [uniqBy(availableOptions, option => JSON.stringify(option.value)), hasZoomOptions];
  }, [leaders, calendar, meeting?.conference_provider, meeting?.conference_leader, meeting?.leader_info,
    meeting?.leaders, user, zoomSettings, isOwner]);

  const meetingLocationsArray: string[] = useMemo(() => {
    const locationDescriptions: string[] = [];
    meetingLocations.locations?.forEach(loc => {
      if (loc.conference) {
        locationDescriptions.push(
          conferenceOptions.find(co => 
            (co.value.provider === loc.conference?.provider && co.value.leader === loc.conference?.leader)
          )?.label || '');
      }
      if (loc.presetLocation) {
        locationDescriptions.push(Object.values(presetLocations).find(pl => pl.id === loc.presetLocation)?.name || '');
      }
      if (loc.room) {
        locationDescriptions.push(meetingRooms[loc.room]?.display_name || '');
      }
      if (loc.locationText) {
        locationDescriptions.push(loc.locationText);
      }
    });
    return locationDescriptions;
  }, [conferenceOptions, meetingLocations.locations, meetingRooms, presetLocations]);  

  const isMdDown = useMediaQuery('(max-width:1030px)');
  const isOneColumn = useMediaQuery('(max-width:845px');

  const meetingTitle = (
    <Box>
      <CabTextTokenDisplay
        templateVars={getTemplateVars(meeting.questions)}
        chipSx={{ fontSize: 18, paddingTop: 0, paddingBottom: 0, height: 24, lineHeight: 24, fontWeight: 'bold' }}
      >
        {meeting.title}
      </CabTextTokenDisplay>
      {!isSmDown && (
        <Typography color={colors.black700}>
          {`Changing these settings will affect all previous links sent out for this 
          ${meeting.is_poll ? 'poll' : 'meeting'}`}
        </Typography>
      )}
    </Box>
  );

  const handleOpenUnavailableTimesModal = () => {
    setUnavailableTimesModalOpen(true);
  };

  const handleCloseUnavailableTimesModal = () => {
    setUnavailableTimesModalOpen(false);
  };

  let calendarOptions = Object.entries(displayedCalendars).filter(
    ([, displayCalendar]) => displayCalendar.display
  ).map(([, displayCalendar]) => ({
    value: displayCalendar.id, label: displayCalendar.name,
  }));

  // in case user does not have access to this calendar we must also include the current meeting calendar as an option,
  // then dedupe
  if (meeting.calendar_info?.calendar_name) {
    calendarOptions = uniqBy([
      ...calendarOptions,
      { value: meeting.calendar_info.id, label: meeting.calendar_info.calendar_name },
    ], cal => cal.value);
  }

  return (
    <>
      {modalOpen && (
        <CabModal
          open={modalOpen}
          onClose={handleModalClose}
          closeIcon={true}
          closeOnBackdropClick={true}
          title={!openPreview ? meetingTitle : ''}
          actionButtons={!meeting.is_poll && isOneColumn ? (
            <Box paddingLeft={1}>
              <CabButton
                key={"preview"}
                buttonType='tertiary'
                onClick={() => setOpenPreview(true)}
                sx={{height: "100%"}}
              >
                <Box display="flex" alignItems="center">
                  <Box sx={{marginRight: 1}}>Preview</Box>
                  <CabIcon alt="Preview" Icon={IoOpenOutline} />
                </Box>
              </CabButton>
            </Box>
          ) : undefined}
          stickyBottomContent={<></>}
          sx={openPreview ? {
            '& .MuiDialog-paper': {
              minWidth: { xs: "auto", sm: 690, md: 900 },
              maxWidth: { sm: 690, md: 900 },
              height: '90%',
            },
            '& .MuiDialogContent-root': {
              padding: '8px 0px 0px 0px'
            },
            '& .MuiDialogTitle-root': {
              '& .MuiButtonBase-root':{
                zIndex: 1000,
                marginRight: 1
              },
            },
          } : {
            '& .MuiDialogContent-root': {
              paddingBottom: 0
            },
            '& .MuiDialog-paper': {
              maxWidth: 1024,
              width: '95%',
              minWidth: {xs: "100%", sm: 512, md: 900},
              minHeight: 560,
              height: {sm: '95%', xs: 'none'}
            },
            '& .MuiDialogActions-spacing': {
              padding: 3,
              paddingTop: 1
            }
          }}
        >
          
          {showEdit && !openPreview && (
            <>
              <Box display='flex' alignItems='center' gap={.75} flexWrap='wrap'>
                <CabIcon 
                  Icon={IoCalendarClearOutline} 
                  size='small' 
                  sx={{color: colors.black700, paddingBottom: '2px'}} 
                />
                <Typography fontSize={15} fontWeight={500} color={colors.black700}>
                  {calendarOptions.find(c => c.value === meeting.booking_calendar)?.label}
                </Typography>
                <Typography fontSize={25} fontWeight={100} color={colors.black700}>•</Typography>
                <CabIcon Icon={IoLocationOutline} size='small' sx={{color: colors.black700, paddingBottom: '2px'}} />
                <Typography fontSize={15} fontWeight={500} color={colors.black700}>
                  {meetingLocationsArray[0]}
                </Typography>

                {meetingLocationsArray.length > 1 && (
                  <CabTooltip 
                    title={meetingLocationsArray.map((loc, idx) => {
                      return <span key={idx}>{loc}<br /></span>;
                    })}
                  >
                    <Typography fontSize={15} fontWeight={500} color={colors.greenPrimary}>
                      +{meetingLocationsArray.length - 1}
                    </Typography>
                  </CabTooltip>
                )}
                {!isSmDown && (
                  <>
                    <Typography fontSize={25} fontWeight={100} marginRight={.5} color={colors.black700}>•</Typography>
                    <CabButton
                      key={"edit"}
                      buttonType='tertiary'
                      icon={<CabIcon alt="Edit" Icon={IoCreateOutline} />}
                      onClick={onEdit}
                      sx={{border: 0, paddingLeft: 0, paddingRight: 0, paddingTop: '2px', ":hover": {border: 0}}}
                    >
                      Edit {meeting.is_reusable ? "Reusable Meeting" : (meeting.is_poll ? "Poll" : "One-Off Meeting")}
                    </CabButton>
                  </>
                )}
              </Box>
              <Divider sx={{marginTop: 1, marginLeft: -3, marginRight: -3}} />
            </>
          )}
          {!openPreview ? (
            <Box
              display='flex'
              flexDirection={isOneColumn ? 'column' : 'row'}
              width={'100%'}
              height={'calc(100% - 40px)'}
            >
              <Box width={isOneColumn ? '100%' : '50%'}
                borderRight={isOneColumn ? 'unset' : `1px solid ${colors.black200}`} height={'100%'}>
                <Box display='flex' flexDirection='column' gap={2} paddingTop={2} paddingBottom={1} paddingRight={2} 
                  height={'100%'} boxSizing='border-box'>
                  <Box display='flex' flexDirection='column' gap={1}>
                    <Typography variant="h2"> Share Link </Typography>
                    <CopyLink
                      link={getBookingLinkUrl(meeting)}
                      callback={() => trackEvent(EVENT_TYPE.SCHEDULING_COPY_LINK)}
                      sx={{width: "100%"}}
                    />
                  </Box>
                  {meeting.is_poll ? (
                    <AttendeeList
                      attendees={attendees}
                      onDeleteAttendee={onDeleteAttendee}
                      onAddAttendee={onAddAttendee}
                      onUpdateAttendee={onUpdateAttendee}
                      participantAutocompleteOptions={participantAutocompleteOptions}
                      fetchAutocompleteOptions={fetchAutocompleteOptions}
                      isMdDown={isMdDown}
                      hasAttendeeListOptions={hasAttendeeListOptions}
                      meetingIsSaved={meeting.id > 0}
                    />
                  ) : (
                    <ShareTimes
                      hyperlinkedSlots={hyperlinkedSlots}
                      formattedSlots={formattedSlots}
                      templateTimezone={templateTimezone}
                      onTemplateTimezoneSelect={onTemplateTimezoneSelect}
                      additionalCopyTimezones={additionalCopyTimezones}
                      onAddAdditionalCopyTimezone={handleAddAdditionalCopyTimezone}
                      onRemoveAdditionalCopyTimezone={handleRemoveAdditionalCopyTimezone}
                      onChangeAdditionalCopyTimezone={handleChangeAdditionalCopyTimezone}
                      loadingAdditionalTimezone={loadingAdditionalTimezone}
                      loadingTimes={loadingTimes}
                      emptySlotMessage={emptyMessage}
                      infoMessage={infoMessage}
                      hasMultiTimezone={user?.features.MULTI_TIMEZONE}
                      openUpgradeModal={openUpgradeModal}
                      showTimesWithLinks={showTimesWithLinks}
                      copyAlert={copyAlert}
                      copyTimes={copyTimes}
                      openUnavailableSlotsModal={handleOpenUnavailableTimesModal}
                      isSmDown={isSmDown}
                    />
                  )}
                </Box>
              </Box>
              <Box width={isOneColumn ? '100%' : '50%'}  height={'100%'}>
                <Box display='flex' flexDirection='column' paddingTop={2} paddingBottom={1} paddingLeft={2} 
                  height={'100%'} boxSizing='border-box'>
                  <BookingOptionsPanel
                    meeting={meeting}
                    user={user}
                    onChangeValues={onChangeValues}
                    openUpgradeModal={openUpgradeModal}
                    mergeSlots={mergeSlots}
                    onToggleMergeSlots={handleToggleMergeSlots}
                    showTimesWithLinks={showTimesWithLinks}
                    onToggleShowTimesWithLinks={handleToggleShowTimesWithLinks}
                    setOpenPreview={setOpenPreview}
                    onAllowVotersToSelectFromList={onAllowVotersToSelectFromList}
                    hasAttendeeListOptions={hasAttendeeListOptions}
                    isOneColumn={isOneColumn}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            <Box display='flex' height='100%'>
              <BookMeeting
                externalId={meeting.external_id}
                isPreview
              />
            </Box>
          )}
        </CabModal>
      )}
      <UnavailableTimesModal 
        modalOpen={unavailableTimesModalOpen}
        onModalClose={handleCloseUnavailableTimesModal}
        unavailableSlots={unavailableSlots}
        timezone={timezone}
      />
      <Snackbar
        open={isCopyError}
        onClose={(event: Event | SyntheticEvent<unknown, Event>, reason: SnackbarCloseReason) => {
          if (reason !== 'clickaway') {
            setIsCopyError(false);
          }
        }}
        autoHideDuration={5000}
        message={'Could not copy text with links'}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      />
    </>
  );
};

export default ScheduleShareModal;